export type TextTheme = 'success' | 'danger' | 'label' | 'strong' | 'text' | 'weak' | 'primary' | 'warning' | 'cost' | undefined;

export const TxStatusMap: {
  [key: number]: {
    text: string;
    theme: TextTheme
  }
} = {
  0: {
    text: '交易成功',
    theme: 'success',
  },
  1: {
    text: '交易失败',
    theme: 'danger',
  }
};

// 合约类型大类映射，不在以下key的合约类型归类为“其他”
export const ContractTypeMap: {
  [key: string]: {
    ctype: string;
  };
} = {
  CMDFA: {
    ctype: 'ft', // FT / token
  }, 
  ERC20: {
    ctype: 'ft', // FT / token
  }, 
  CMNFA: {
    ctype: 'nft', // NFT
  }, 
  ERC721: {
    ctype: 'nft', // NFT
  },
};

export const SubChainStatusMap: {
  [key: string]: {
    text: string
  }
} = {
  0: { text: '正常' },
  1: { text: '离线' },
};

export const SubChainTypeMap: {
  [key: string]: {
    text: string
  }
} = {
  1: { text: '长安链' },
  2: { text: 'fabric' },
  3: { text: 'BCOS' },
  4: { text: 'eth' },
  5: { text: '+ 扩展' },
};

export const CrossTxStatusMap: {
  [key: string]: {
    text: string
  }
} = {
  0: { text: '交易进行中' },
  1: { text: '交易进行中' },
  2: { text: '交易进行中' },
  3: { text: '交易成功' },
  4: { text: '交易失败' },
};
