import React, { useCallback, useEffect, useMemo, useState } from 'react';
import style from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import { Decimal } from '@src/utils/apis';
import { ChainInfo } from '@src/models';
import { formatNumberToThousands } from '@src/utils/tools';
import TWEEN from '@tweenjs/tween.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsRotate,
  faCubes,
  faFileContract,
  faUsers,
  faBuildingColumns,
  faAtom,
} from '@fortawesome/free-solid-svg-icons';
import { RootReducer } from '@src/store';
import { useSelector } from 'react-redux';
let timeManage: any;
export default function Statistics() {
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const authType = useMemo(() => currentChain?.AuthType, [currentChain]);
  const navigate = useNavigate();
  // 递归触发变量
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const [detail, setDetail] = useState<ChainInfo | null>(null);
  // 数字动画函数
  const animateCount = useCallback(
    (data: ChainInfo) => {
      const from = {
        // detail ||
        BlockHeight: detail?.BlockHeight === data.BlockHeight ? data.BlockHeight : 0,
        RecentBlockHeight: detail?.RecentBlockHeight === data.RecentBlockHeight ? data.RecentBlockHeight : 0,
        TxCount: detail?.TxCount === data.TxCount ? data.TxCount : 0,
        RecentTxNum: detail?.RecentTxNum === data.RecentTxNum ? data.RecentTxNum : 0,
        ContractCount: detail?.ContractCount === data.ContractCount ? data.ContractCount : 0,
        RecentContractNum: detail?.RecentContractNum === data.RecentContractNum ? data.RecentContractNum : 0,
        UserCount: detail?.UserCount === data.UserCount ? data.UserCount : 0,
        RecentUserNum: detail?.RecentUserNum === data.RecentUserNum ? data.RecentUserNum : 0,
        OrgCount: detail?.OrgCount === data.OrgCount ? data.OrgCount : 0,
        RunningNode: detail?.RunningNode === data.RunningNode ? data.RunningNode : 0,
      };
      const to = data;
      new TWEEN.Tween(from)
        .to(to, 1000)
        .duration(500)
        .easing(TWEEN.Easing.Quadratic.Out)
        .onUpdate((info) => {
          const showinfo = {
            BlockHeight: Math.floor(info.BlockHeight),
            RecentBlockHeight: Math.floor(info.RecentBlockHeight),
            TxCount: Math.floor(info.TxCount),
            RecentTxNum: Math.floor(info.RecentTxNum),
            ContractCount: Math.floor(info.ContractCount),
            RecentContractNum: Math.floor(info.RecentContractNum),
            UserCount: Math.floor(info.UserCount),
            RecentUserNum: Math.floor(info.RecentUserNum),
            OrgCount: Math.floor(info.OrgCount),
            RunningNode: Math.floor(info.RunningNode),
          };
          setDetail(showinfo);
        })
        .start();
      clearTimeout(timeManage);
      timeManage = setTimeout(() => {
        setRefreshCount(refreshCount + 1);
      }, 1000);
    },
    [detail, refreshCount],
  );
  const updateData = useCallback(() => {
    Decimal({
      ChainId: chainId,
    })
      .then((res) => {
        if (res.Data) {
          animateCount(res.Data);
        }
      })
      .catch(() => {
        setRefreshCount(refreshCount + 1);
      });
  }, [chainId, animateCount, refreshCount]);
  useEffect(() => {
    updateData();
  }, [refreshCount]);
  return (
    <div className={style.block}>
      <div className={`${style.block_i} ${style.block_height}`} onClick={() => navigate(`/${chainId}/block`)}>
        <div className={style.icon_c}>
          <FontAwesomeIcon icon={faCubes} className={style.icon_svg} />
        </div>
        <div className={style.block_item}>
          <p className={style.block_count}>{formatNumberToThousands(detail?.BlockHeight)}</p>
          <p className={style.block_name}>区块高度</p>
        </div>
      </div>
      <div className={`${style.block_i} ${style.block_tx}`} onClick={() => navigate(`/${chainId}/transaction`)}>
        <div className={style.icon_c}>
          <FontAwesomeIcon icon={faArrowsRotate} className={style.icon_svg} />
        </div>
        <div className={style.block_item}>
          <p className={style.block_count}>{formatNumberToThousands(detail?.TxCount)}</p>
          <p className={style.block_name}>累计交易数</p>
        </div>
      </div>
      <div className={`${style.block_i} ${style.block_contract}`} onClick={() => navigate(`/${chainId}/contract`)}>
        <div className={style.icon_c}>
          <FontAwesomeIcon icon={faFileContract} className={style.icon_svg} />
        </div>
        <div className={style.block_item}>
          <p className={style.block_count}>{formatNumberToThousands(detail?.ContractCount)}</p>
          <p className={style.block_name}>累计合约数</p>
        </div>
      </div>
      {authType === 'permissionedwithcert' && (
        <div className={`${style.block_i} ${style.block_org}`} onClick={() => navigate(`/${chainId}/origin`)}>
          <div className={style.icon_c}>
            <FontAwesomeIcon icon={faBuildingColumns} className={style.icon_svg} />
          </div>
          <div className={style.block_item}>
            <p className={style.block_count}>{formatNumberToThousands(detail?.OrgCount)}</p>
            <p className={style.block_name}>组织数</p>
          </div>
        </div>
      )}
      <div className={`${style.block_i} ${style.block_node}`} onClick={() => navigate(`/${chainId}/node`)}>
        <div className={style.icon_c}>
          <FontAwesomeIcon icon={faAtom} className={style.icon_svg} />
        </div>
        <div className={style.block_item}>
          <p className={style.block_count}>{formatNumberToThousands(detail?.RunningNode)}</p>
          <p className={style.block_name}>节点数</p>
        </div>
      </div>
      <div className={`${style.block_i} ${style.block_user}`} onClick={() => navigate(`/${chainId}/user`)}>
        <div className={style.icon_c}>
          <FontAwesomeIcon icon={faUsers} className={style.icon_svg} />
        </div>
        <div className={style.block_item}>
          <p className={style.block_count}>{formatNumberToThousands(detail?.UserCount)}</p>
          <p className={style.block_name}>链上用户数</p>
        </div>
      </div>
    </div>
  );
}
