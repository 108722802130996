import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'tea-component/dist/tea.css';
import '@src/assets/styles/teaCssCover.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { Provider } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCoffee, faCubes } from '@fortawesome/free-solid-svg-icons';
import './utils/bgcanvas.js';

library.add(fab, faCubes, faCoffee);
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
