import { UiPaging } from '@src/models';

export interface GetBlockListParamState extends UiPaging {
  value: string;
}

export const blockListParamReducer = (
  state: GetBlockListParamState = {
    value: '',
    pageIndex: 1,
    pageSize: 10,
  },
  action: {
    type: string;
    payload?: GetBlockListParamState;
  },
): GetBlockListParamState => {
  if (action.type === 'UPDATE_BLOCKLIST_PARAM') {
    if (action.payload) {
      return { ...state, ...action.payload };
    }
  }
  return state;
};

export interface GetTxListParamInfo extends UiPaging {
  value: string;
  sender: string;
  userAddr: string;
  starttime: number | null;
  endtime: number | null;
}

export const txListParamReducer = (
  state: GetTxListParamInfo = {
    value: '',
    sender: '',
    userAddr: '',
    starttime: null,// new Date().getTime()-30*24*60*60*1000,
    endtime: null,// new Date().getTime(),
    pageIndex: 1,
    pageSize: 10,
  },
  action: {
    type: string;
    payload?: GetTxListParamInfo;
  },
): GetTxListParamInfo => {
  if (action.type === 'UPDATE_TXLIST_PARAM') {
    if (action.payload) {
      return { ...state, ...action.payload };
    }
  }
  return state;
};

export interface GetContractListParamInfo extends UiPaging {
  value: string;
  sender: string;
  tabActiveId: 'ALL' | 'FT' | 'NFT';
}

export const contractListParamReducer = (
  state: GetContractListParamInfo = {
    value: '',
    sender: '',
    pageIndex: 1,
    pageSize: 10,
    tabActiveId: 'ALL'
  },
  action: {
    type: string;
    payload?: GetContractListParamInfo;
  },
): GetContractListParamInfo => {
  if (action.type === 'UPDATE_CONTRACTLIST_PARAM') {
    if (action.payload) {
      return { ...state, ...action.payload };
    }
  }
  return state;
};

export interface GetUserListParamState extends UiPaging {
  value: string;
  orgId: string;
}

export const userListParamReducer = (
  state: GetUserListParamState = {
    value: '',
    orgId: '',
    pageIndex: 1,
    pageSize: 10,
  },
  action: {
    type: string;
    payload?: GetUserListParamState;
  },
): GetUserListParamState => {
  if (action.type === 'UPDATE_USERLIST_PARAM') {
    if (action.payload) {
      return { ...state, ...action.payload };
    }
  }
  return state;
};

export interface GetNodeListParamState extends UiPaging {
  nodeId: string;
  nodeName: string;
  orgId: string;
}

export const nodeListParamReducer = (
  state: GetNodeListParamState = {
    nodeId: '',
    nodeName: '',
    orgId: '',
    pageIndex: 1,
    pageSize: 10,
  },
  action: {
    type: string;
    payload?: GetNodeListParamState;
  },
): GetNodeListParamState => {
  if (action.type === 'UPDATE_NODELIST_PARAM') {
    if (action.payload) {
      return { ...state, ...action.payload };
    }
  }
  return state;
};

export interface GetOrgListParamState extends UiPaging {
  value: string;
}

export const orgListParamReducer = (
  state: GetOrgListParamState = {
    value: '',
    pageIndex: 1,
    pageSize: 10,
  },
  action: {
    type: string;
    payload?: GetOrgListParamState;
  },
): GetOrgListParamState => {
  if (action.type === 'UPDATE_ORGLIST_PARAM') {
    if (action.payload) {
      return { ...state, ...action.payload };
    }
  }
  return state;
};
