import React, { useCallback, useEffect, useMemo, useState } from 'react';
import liststyle from '../list.module.scss';
import PageBox from '@components/ui/PageBox';
import { Text, Justify, Table, TableColumn, Form, Input, Button } from 'tea-component';
// import SearchInput from '@components/ui/SearchInput';
import { GetTxList, GetAccountDetail } from '@src/utils/apis';
import { Tx } from '@src/models';
import { formatUnixTime } from '@src/utils/tools';
import { Link } from 'react-router-dom';
import { RangePicker } from 'tea-component/lib/datepicker/RangePicker';
import { useDispatch, useSelector } from 'react-redux';

import SearchLink from '@src/components/form/SearchLink';
import { RootReducer } from '@src/store';
import { TxStatusMap } from '@src/constant/index';
import { UserAddress } from '@src/components/ui/AttrShow/UserAddress';
const { pageable, autotip } = Table.addons;

export default function Transaction() {
  const dispatch = useDispatch();
  // const now = new Date().getTime();
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const authType = useMemo(() => currentChain?.AuthType, [currentChain]);
  const { value, sender, userAddr, starttime, endtime, pageIndex, pageSize } = useSelector(
    (state: RootReducer) => state.txListParamReducer,
  );
  const updateparam = useCallback((payload) => {
    dispatch({
      type: 'UPDATE_TXLIST_PARAM',
      payload,
    });
  }, []);
  const [list, setList] = useState<Tx[]>([]);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const updateTime = useCallback(
    (value) => {
      updateparam({
        starttime: value[0].toDate().getTime(),
        endtime: value[1].toDate().getTime(),
      });
    },
    [updateparam],
  );
  const getList = async () => {
    setIsLoading(true);
    const params = {
      ChainId: chainId,
      Limit: pageSize,
      TxId: value,
      UserAddrs: userAddr,
      Offset: pageIndex - 1,
      Senders: sender,
      StartTime: starttime ? Math.floor(starttime / 1000) : 0,
      EndTime: endtime ? Math.ceil(endtime / 1000) : 0,
    };
    if (/cnbn$/.test(userAddr)) {
      const res = await GetAccountDetail({
        BNS: userAddr,
        ChainId: chainId || '',
      });
      if (res?.Data?.Address) {
        params.UserAddrs = res.Data.Address;
      }
    };
    GetTxList(params)
      .then((res) => {
        setIsLoading(false);
        if (res.GroupList) {
          setList(res.GroupList);
          setRecordCount(res.TotalCount);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const columns = useMemo<TableColumn[]>(() => {
    const list: TableColumn<Tx>[] = [
      {
        key: 'BlockHeight',
        header: '区块高度',
        align: 'left',
        width: 100,
        render: ({ BlockHeight, BlockHash }) => <Link to={`/${chainId}/block/${BlockHash}`}>{BlockHeight}</Link>,
      },
      {
        key: 'TxId',
        header: '交易Id',
        align: 'left',
        width: 220,
        render: ({ TxId }) => <Link to={`/${chainId}/transaction/${TxId}`}>{TxId}</Link>,
      },
      // {
      //   key: 'SenderOrgId',
      //   header: '发起组织',
      //   align: 'left',
      // },
      {
        key: 'Sender',
        header: '发起用户',
        align: 'left',
        render: ({ Sender, UserAddr, UserAddrBns }) => <UserAddress UserAddrBns={UserAddrBns} Sender={Sender} UserAddr={UserAddr} />
        // authType === 'permissionedwithcert'
        //   ? ({ Sender, UserAddr }) => <Bubble content={UserAddr}>{Sender}</Bubble>
        //   : ({ UserAddr }) => UserAddr || '--',
      },
      {
        key: 'ContractName',
        header: '目标合约',
        align: 'left',
        render: ({ ContractName, ContractAddr }) => (
          <SearchLink type="5" keyWord={ContractAddr}>
            {ContractName}
          </SearchLink>
        ),
      },
      {
        key: 'TxStatusCode',
        header: '交易状态',
        align: 'left',
        width: 100,
        render: ({ TxStatus }) => (
          <Text theme={TxStatusMap[TxStatus].theme}>{TxStatusMap[TxStatus].text}</Text>
        ),
      },
      {
        key: 'Timestamp',
        header: '上链时间',
        align: 'left',
        render: ({ Timestamp }) => formatUnixTime(Timestamp),
        width: 180,
      },
      {
        key: 'Option',
        header: '操作',
        align: 'center',
        width: 100,
        render: ({ TxId }) => (
          <div className={liststyle.setting_c}>
            <Link to={`/${chainId}/transaction/${TxId}`}>查看</Link>
          </div>
        ),
      },
    ];
    if (authType === 'permissionedwithcert') {
      list.splice(2, 0, {
        key: 'SenderOrgId',
        header: '发起组织',
        align: 'left',
      });
    }
    return list;
  }, []);
  useEffect(() => {
    getList();
  }, [chainId, pageIndex, pageSize]);
  const onSearch = () => {
    updateparam({ pageIndex: 1 });
    getList();
  };
  return (
    <PageBox title="交易列表">
      <Justify
        right={
          <>
            <Form className={liststyle.searchform} hideLabel={false} layout="inline">
              <Form.Item label="起止时间">
                <RangePicker onChange={updateTime} />
              </Form.Item>
              {authType === 'permissionedwithcert' && (
                <Form.Item label="用户名">
                  <Input
                    onChange={(sender) => updateparam({ sender })}
                    value={sender}
                    placeholder="请输发起用户名"
                  />
                </Form.Item>
              )}
              <Form.Item label="交易Id">
                <Input onChange={(value) => updateparam({ value })} value={value} placeholder="请输入交易Id搜索" />
              </Form.Item>
              <Form.Item label="账户地址">
                <Input
                  onChange={(userAddr) => updateparam({ userAddr })}
                  value={userAddr}
                  // placeholder="请输入用户地址"
                  placeholder={authType === 'permissionedwithcert' ? '请输入发起账户地址/链账户名称' : '请输入发起账户地址/BNS'}
                />
              </Form.Item>
              <Form.Item label="">
                <Button type="primary" onClick={onSearch}>
                  筛选
                </Button>
              </Form.Item>
            </Form>
          </>
          // <SearchInput
          //   placeholder="输入交易Id搜索"
          //   onSubmit={getList}
          //   onChange={(value) => setValue(value)}
          //   value={value}
          // />
        }
      />
      <Table
        className={liststyle.table}
        compact={false}
        records={list}
        recordKey="TxId"
        bordered={true}
        disableTextOverflow={true}
        columns={columns}
        addons={[
          autotip({
            isLoading,
          }),
          pageable({
            recordCount,
            pageIndex,
            pageSize,
            onPagingChange: (query) => {
              if (query?.pageIndex) {
                updateparam({ pageIndex: query.pageIndex });
              }
              if (query?.pageSize) {
                updateparam({ pageSize: query.pageSize });
              }
            },
          }),
        ]}
      />
    </PageBox>
  );
}
