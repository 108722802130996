import { createGetChannel, createPostChannel } from './request';
import {
  ChainItem,
  ChainInfo,
  BlockItem,
  Tx,
  ContractItem,
  BlockInfo,
  ContractInfo,
  TxInfo,
  ChainListParam,
  GetDecimalParam,
  GetBlockListParam,
  GetTxListParam,
  GetContractListParam,
  GetOrgListParam,
  OriginItem,
  UserItem,
  GetUserListParam,
  SearchParam,
  SearchInfo,
  CrossSearchParam,
  CrossSearchInfo,
  GetEventListParam,
  EventItem,
  Subscribe,
  CancelSubscribeParam,
  GetNodeListParam,
  NodeItem,
  GetContractCodeParam,
  GetContractCodeRes,
  GetContractVersionListParam,
  GetContractVersionListItem,
  GetLatestTxListParam,
  GetLatestTxListItem,
  GetLatestBlockListParam,
  GetLatestBlockListItem,
  GetBlockDetailParam,
  GetTxNumByTimeParam,
  GetTxNumByTimeItem,
  GetLatestContractListParam,
  GetLatestContractListItem,
  DeleteSubscribeParam,
  GetNFTContractListParam,
  NFTContractItem,
  GetFTContractListParam,
  FTContractItem,
  GetFTPositionListParam,
  FTPositionItem,
  GetNFTPositionListParam,
  NFTPositionItem,
  FTContractDetail,
  GetFTContractDetailParam,
  NFTContractDetail,
  AccountDetail,
  GetNFTContractDetailParam,
  GetAccountDetailParam,
  GetNFTTransferListParam,
  NFTTransferItem,
  GetFTTransferListParam,
  FTTransferItem,
  NFTItem,
  GetNFTListParam,
  GetNFTDetailParam,
  NFTDetail,
  CrossOverviewDataInfo,
  CrossLatestTxListItem,
  CrossLatestSubChainListItem,
  CrossSubChainListParam,
  CrossSubChainListItem,
  ParamWithChainId,
  ParamWithSubChainId,
  CrossSubChainDetailInfo,
  ParamWithCrossId,
  CrossTxDetailInfo,
  SubChainCrossChainListItem,
  GetCrossTxListParam,
  CrossTxListItem,
} from '@src/models';
import { Fetch, ResponseData, ResponseList } from './type';
import { GetTransferListParam, TransferItem } from '@src/models/transfer';
// import { ChainListParam } from '@src/models';

// 链配置是否显示
export const GetChainConfig: Fetch<{}, ResponseData<boolean>> = createGetChannel('GetChainConfig');
// 查看链列表
export const GetChainList: Fetch<ChainListParam, ResponseList<ChainItem>> = createGetChannel('GetChainList');
// 首页查询
export const Search: Fetch<SearchParam, ResponseData<SearchInfo>> = createGetChannel('Search');

// 首页数据统计
export const Decimal: Fetch<GetDecimalParam, ResponseData<ChainInfo>> = createGetChannel('GetOverviewData');

// 按时间段查询交易量
export const GetTxNumByTime: Fetch<GetTxNumByTimeParam, ResponseList<GetTxNumByTimeItem>> = createGetChannel(
  'GetTxNumByTime',
);

// 交易统计曲线
// export const GetTransactionNumByTime: Fetch<
//   GetTransactionNumByTimeParam,
//   ResponseList<TransactionNumByTime>
// > = createGetChannel('GetTransactionNumByTime');

// 组织列表
export const GetOrgList: Fetch<GetOrgListParam, ResponseList<OriginItem>> = createGetChannel('GetOrgList');

// 获取链上用户列表
export const GetUserList: Fetch<GetUserListParam, ResponseList<UserItem>> = createGetChannel('GetUserList');

// 节点列表
export const GetNodeList: Fetch<GetNodeListParam, ResponseList<NodeItem>> = createGetChannel('GetNodeList');

// 查看区块列表
export const GetBlockList: Fetch<GetBlockListParam, ResponseList<BlockItem>> = createGetChannel('GetBlockList');

export const GetLatestBlockList: Fetch<GetLatestBlockListParam, ResponseList<GetLatestBlockListItem>> = createGetChannel('GetLatestBlockList');

// 查看交易列表
export const GetTxList: Fetch<GetTxListParam, ResponseList<Tx>> = createGetChannel('GetTxList');

export const GetLatestTxList: Fetch<GetLatestTxListParam, ResponseList<GetLatestTxListItem>> = createGetChannel(
  'GetLatestTxList',
);

// 最新交易列表
export const GetLatestContractList: Fetch<
GetLatestContractListParam,
ResponseList<GetLatestContractListItem>
> = createGetChannel('GetLatestContractList');
// 合约列表
export const GetContractList: Fetch<GetContractListParam, ResponseList<ContractItem>> = createGetChannel(
  'GetContractList',
);

//  查询区块详情
export const GetBlockDetail: Fetch<GetBlockDetailParam, ResponseData<BlockInfo>> = createGetChannel('GetBlockDetail');

// 查询交易详情

export const GetTxDetail: Fetch<any, ResponseData<TxInfo>> = createGetChannel('GetTxDetail');
// 合约详情
export const GetContractDetail: Fetch<{ChainId: string;ContractKey: string}, ResponseData<ContractInfo>> = createGetChannel('GetContractDetail');
// 合约源码
export const GetContractCode: Fetch<GetContractCodeParam, ResponseData<GetContractCodeRes>> = createGetChannel(
  'GetContractCode',
);
// 获取合约版本交易列表
export const GetContractVersionList: Fetch<
GetContractVersionListParam,
ResponseList<GetContractVersionListItem>
> = createGetChannel('GetContractVersionList');
// 获取合约事件列表
export const GetEventList: Fetch<GetEventListParam, ResponseList<EventItem>> = createGetChannel('GetEventList');
// 订阅链（绑定链）
export const SubscribeChain: Fetch<Subscribe, ResponseData<ChainItem>> = createPostChannel('SubscribeChain');
// 取消订阅信息
export const CancelSubscribe: Fetch<CancelSubscribeParam, ResponseData<ChainItem>> = createPostChannel(
  'CancelSubscribe',
);
// 修改订阅信息
export const ModifySubscribe: Fetch<Subscribe, ResponseData<ChainItem>> = createPostChannel('ModifySubscribe');
// 链删除
export const DeleteSubscribe: Fetch<DeleteSubscribeParam, ResponseData<string>> = createPostChannel('DeleteSubscribe');

// 查询流转记录列表 api 已废弃 2024-01-22
export const GetTransferList: Fetch<GetTransferListParam, ResponseList<TransferItem>> = createGetChannel(
  'GetTransferList',
);
export const GetNFTDetail: Fetch<GetNFTDetailParam, ResponseData<NFTDetail>> = createGetChannel('GetNFTDetail');

// 获取NFT合约列表(合约列表页的NFT类)
export const GetNFTContractList: Fetch<GetNFTContractListParam, ResponseList<NFTContractItem>> = createGetChannel('GetNFTContractList');

// 获取NFT列表
export const GetNFTList: Fetch<GetNFTListParam, ResponseList<NFTItem>> = createGetChannel('GetNFTList');

// 获取FT合约列表(合约列表页的Token类)
export const GetFTContractList: Fetch<GetFTContractListParam, ResponseList<FTContractItem>> = createGetChannel('GetFTContractList');

// 获取FT合约持仓列表
export const GetFTPositionList: Fetch<GetFTPositionListParam, ResponseList<FTPositionItem>> = createGetChannel('GetFTPositionList');

// 获取NFT合约持仓列表
export const GetNFTPositionList: Fetch<GetNFTPositionListParam, ResponseList<NFTPositionItem>> = createGetChannel('GetNFTPositionList');

// 获取FT合约详情
export const GetFTContractDetail: Fetch<GetFTContractDetailParam, ResponseData<FTContractDetail>> = createGetChannel('GetFTContractDetail');

// 获取NFT合约详情
export const GetNFTContractDetail: Fetch<GetNFTContractDetailParam, ResponseData<NFTContractDetail>> = createGetChannel('GetNFTContractDetail');

// 获取FT流转列表
export const GetFTTransferList: Fetch<GetFTTransferListParam, ResponseList<FTTransferItem>> = createGetChannel('GetFTTransferList');

// 获取FT流转列表
export const GetNFTTransferList: Fetch<GetNFTTransferListParam, ResponseList<NFTTransferItem>> = createGetChannel('GetNFTTransferList');

// 获取链账户详情
export const GetAccountDetail: Fetch<GetAccountDetailParam, ResponseData<AccountDetail>> = createGetChannel('GetAccountDetail');

// 主子链网配置。根据链配置确定是否展示主子链tag标签
export const GetMainCrossConfig: Fetch<ParamWithChainId, ResponseData<{ShowTag: boolean}>> = createGetChannel('GetMainCrossConfig');

// 首页搜索接口，返回数据是否存在，并返回type对应的查询结果
export const CrossSearch: Fetch<CrossSearchParam, ResponseData<CrossSearchInfo>> = createGetChannel('CrossSearch');

// 首页详情数据
export const CrossOverviewData: Fetch<ParamWithChainId, ResponseData<CrossOverviewDataInfo>> = createGetChannel('CrossOverviewData');

// 获取最新跨链交易列表
export const CrossLatestTxList: Fetch<ParamWithChainId, ResponseList<CrossLatestTxListItem>> = createGetChannel('CrossLatestTxList');

// 获取最新子链列表
export const CrossLatestSubChainList: Fetch<ParamWithChainId, ResponseList<CrossLatestSubChainListItem>> = createGetChannel('CrossLatestSubChainList');

// 获取子链列表
export const CrossSubChainList: Fetch<CrossSubChainListParam, ResponseList<CrossSubChainListItem>> = createGetChannel('CrossSubChainList');

// 获取子链详情
export const CrossSubChainDetail: Fetch<ParamWithSubChainId, ResponseData<CrossSubChainDetailInfo>> = createGetChannel('CrossSubChainDetail');

// 获取跨链交易详情
export const GetCrossTxDetail: Fetch<ParamWithCrossId, ResponseData<CrossTxDetailInfo>> = createGetChannel('GetCrossTxDetail');

// 获取子链历史跨链列表
export const SubChainCrossChainList: Fetch<ParamWithSubChainId, ResponseList<SubChainCrossChainListItem>> = createGetChannel('SubChainCrossChainList');

// 获取跨链交易列表
export const GetCrossTxList: Fetch<GetCrossTxListParam, ResponseList<CrossTxListItem>> = createGetChannel('GetCrossTxList');