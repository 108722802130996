import React, { useCallback, useEffect, useMemo, useState } from 'react';
import liststyle from '../list.module.scss';
import PageBox from '@src/components/ui/PageBox';
import { Text, Justify, Table, TableColumn, Bubble, Form, Input, Button } from 'tea-component';
import { BlockItem } from '@src/models';
import { GetBlockList } from '@src/utils/apis';
import { Link } from 'react-router-dom';
import { formatUnixTime } from '@src/utils/tools';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducer } from '@src/store';
const { pageable, autotip } = Table.addons;

export default function Block() {
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const authType = useMemo(() => currentChain?.AuthType, [currentChain]);
  const dispatch = useDispatch();
  const { value, pageIndex, pageSize } = useSelector((state: RootReducer) => state.blockListParamReducer);
  const updateparam = useCallback((payload) => {
    dispatch({
      type: 'UPDATE_BLOCKLIST_PARAM',
      payload,
    });
  }, []);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [list, setList] = useState<BlockItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const getList = useCallback(() => {
    setIsLoading(true);
    GetBlockList({
      BlockKey: value,
      ChainId: chainId,
      Limit: pageSize,
      Offset: pageIndex - 1,
    })
      .then((res) => {
        setIsLoading(false);
        if (res.GroupList) {
          setList(res.GroupList);
          setRecordCount(res.TotalCount);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [chainId, value, pageIndex, pageSize]);
  useEffect(() => {
    getList();
  }, [chainId, pageIndex, pageSize]);
  const onSearch = useCallback(() => {
    updateparam({ pageIndex: 1 });
    getList();
  }, [getList]);
  const columns = useMemo<TableColumn[]>(
    () => [
      {
        key: 'BlockHeight',
        header: '区块高度',
        align: 'left',
        width: 100,
        render: ({ BlockHeight, BlockHash }) => <Link to={`/${chainId}/block/${BlockHash}`}>{BlockHeight}</Link>,
      },
      {
        key: 'BlockHash',
        header: '区块Hash',
        align: 'left',
        render: ({ BlockHash }) => <Link to={`/${chainId}/block/${BlockHash}`}>{BlockHash}</Link>,
      },
      {
        key: 'TxNum',
        header: '交易数',
        align: 'left',
        width: 100,
      },
      {
        key: 'ProposalNodeId',
        header: '出块节点',
        align: 'left',
        render:
          authType === 'permissionedwithcert'
            ? ({ ProposalNodeId, ProposalNodeAddr }) => <Bubble content={ProposalNodeAddr}>{ProposalNodeId}</Bubble>
            : undefined,
      },
      {
        key: 'Timestamp',
        header: '区块生成时间',
        align: 'left',
        width: 200,
        render: ({ Timestamp }) => formatUnixTime(Timestamp),
      },
      {
        key: 'Id',
        header: '操作',
        align: 'center',
        width: 160,
        render: ({ BlockHash }) => (
          <div className={liststyle.setting_c}>
            <Link to={`/${chainId}/block/${BlockHash}`}>
              <Text theme="primary">查看</Text>
            </Link>
          </div>
        ),
      },
    ],
    [],
  );
  return (
    <PageBox title="区块列表">
      <Justify
        right={
          // <SearchInput
          //   placeholder="请输入区块哈希/区块号搜索"
          //   onChange={(value) => updateparam({ value })}
          //   onSubmit={onSearch}
          //   value={value}
          // />
          <Form className={liststyle.searchform} hideLabel={false} layout="inline">
            <Form.Item label="区块哈希">
              <Input
                onChange={(value) => updateparam({ value })}
                value={value}
                placeholder="请输入区块哈希/区块号搜索"
              />
            </Form.Item>
            <Form.Item label="">
              <Button type="primary" onClick={onSearch}>
                  筛选
              </Button>
            </Form.Item>
          </Form>
        }
      />
      <Table
        className={liststyle.table}
        compact={false}
        records={list}
        recordKey="BlockHash"
        bordered={true}
        disableTextOverflow={true}
        columns={columns}
        addons={[
          autotip({
            isLoading,
          }),
          pageable({
            recordCount,
            pageIndex,
            pageSize,
            onPagingChange: (query) => {
              if (query?.pageIndex) {
                updateparam({ pageIndex: query.pageIndex });
              }
              if (query?.pageSize) {
                updateparam({ pageSize: query.pageSize });
              }
            },
          }),
        ]}
      />
    </PageBox>
  );
}
