import React, { useCallback, useEffect, useMemo, useState } from 'react';
import liststyle from '../list.module.scss';
import PageBox from '@components/ui/PageBox';
import { Text, Justify, Table, TableColumn, Button, Form, Input, Tabs } from 'tea-component';
import { ContractItem, NFTContractItem, FTContractItem } from '@src/models';
import { GetContractList, GetNFTContractList, GetFTContractList, GetAccountDetail } from '@src/utils/apis';
import { Link, useSearchParams } from 'react-router-dom';
import { formatUnixTime } from '@src/utils/tools';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducer } from '@src/store';
import SearchLink from '@src/components/form/SearchLink';
import { checkInstallPlugin } from '../components/tools';
import PluginDownloadModel from '@src/components/plugin/download-plugin-model';
import { ContractTypeMap } from '@src/constant/index';
import { UserAddress } from '@src/components/ui/AttrShow/UserAddress';
const { pageable, autotip } = Table.addons;

const tabs = [
  { id: 'ALL', label: '全部合约' },
  { id: 'FT', label: 'Token类' },
  { id: 'NFT', label: 'NFT类' },
];
const APIMap: {
  ALL: typeof GetContractList;
  FT: typeof GetFTContractList;
  NFT: typeof GetNFTContractList;
} = {
  ALL: GetContractList,
  FT: GetFTContractList,
  NFT: GetNFTContractList,
};

export default function Contract() {
  const [searchParams, setSearchParams] = useSearchParams();
  // console.log(searchParams);
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId: string = currentChain?.ChainId ?? '';
  const authType = useMemo(() => currentChain?.AuthType, [currentChain]);
  const dispatch = useDispatch();
  const { value, sender, pageIndex, pageSize, tabActiveId } = useSelector((state: RootReducer) => state.contractListParamReducer);
  const updateparam = useCallback((payload) => {
    dispatch({
      type: 'UPDATE_CONTRACTLIST_PARAM',
      payload,
    });
  }, []);
  const [list, setList] = useState<ContractItem[]|NFTContractItem[]|FTContractItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [pluginDownloadVisible, setPluginDownloadVisible] = useState(false);

  const getList = async () => {
    setIsLoading(true);
    const GetContractDataList = APIMap[tabActiveId];
    const params: {
      ContractKey: string;
      ChainId: string;
      Limit: number;
      Offset: number;
      Creators?: string;
    } = {
      ContractKey: value,
      ChainId: chainId,
      Limit: pageSize,
      Offset: pageIndex - 1,
    };
    if (tabActiveId === 'ALL') {
      params.Creators = sender;
      if(authType!=='permissionedwithcert'&& /cnbn$/.test(sender)){
        const res = await GetAccountDetail({
          BNS: sender,
          ChainId: chainId || '',
        });
        if(res?.Data?.Address){
          params.Creators = res.Data.Address;
        }
      };
    }
    GetContractDataList(params)
      .then((res) => {
        setIsLoading(false);
        const tab = searchParams.get('tab')||'ALL';
        // console.log(tabActiveId,tab);
        if (res.GroupList&& tabActiveId===tab) {
          setList(res.GroupList);
          setRecordCount(res.TotalCount);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const listenContract = useCallback(
    (contractName: string, contractType: string) => {
      if (checkInstallPlugin()) {
        (window as any).chainMaker.sendRequest('importSubscribeContract', {
          body: {
            chainId,
            contractName,
            contractType,
          },
        });
      } else {
        setPluginDownloadVisible(true);
      }
    },
    [chainId],
  );
  // useEffect(()=>{
    
  // },[tabActiveId]);
  useEffect(()=>{
    const tab = searchParams.get('tab')||'ALL';
    if(tab){
      if(tab!== tabActiveId){
        updateparam({
          tabActiveId: tab,
          value: '',
          sender: '',
          pageIndex: 1,
          pageSize: 10,
        });
      }
    }else if(tabActiveId){
      setSearchParams({tab: tabActiveId},{ replace: true });
    }
  },[]);
  useEffect(() => {
    getList();
  }, [chainId, pageIndex, pageSize, tabActiveId]);
  const onSearch = () => {
    updateparam({ pageIndex: 1 });
    getList();
  };
  const columns = useMemo<(TableColumn | null)[]>(
    () => [
      {
        key: 'ContractName',
        header: '合约名称',
        align: 'left',
        width: 160,
        render: ({ ContractName, ContractAddr, ContractType }) =>
          ContractAddr
            ? (
              <Link to={`/${chainId}/contract/${ContractAddr}?ctype=${ContractTypeMap[ContractType]?.ctype ?? 'other'}`}>{ContractName}</Link>
            )
            : (
              <SearchLink type="4">{ContractName}</SearchLink>
            ),
      },
      {
        key: 'ContractAddr',
        header: '合约地址',
        align: 'left',
        render: ({ ContractAddr, ContractType }) => (ContractAddr ? <Link to={`/${chainId}/contract/${ContractAddr}?ctype=${ContractTypeMap[ContractType]?.ctype ?? 'other'}`}>{ContractAddr}</Link> : '--'),
      },
      tabActiveId === 'ALL'
        ? {
          key: 'Version',
          header: '当前版本号',
          align: 'left',
          width: 120,
          render: ({ Version }) => Version || '--',
        }
        : null,
      {
        key: 'ContractType',
        header: '合约类型',
        align: 'left',
        width: 100,
      },
      ['NFT', 'FT'].includes(tabActiveId)
        ? {
          key: 'Timestamp',
          header: '创建时间',
          align: 'left',
          width: 180,
          render: ({ Timestamp }) => (Timestamp ? formatUnixTime(Timestamp) : '--'),
        }
        : null,
      tabActiveId === 'FT'
        ? {
          key: 'ContractSymbol',
          header: 'Token',
          align: 'left',
          width: 120,
        }
        : null,
      ['NFT', 'FT'].includes(tabActiveId)
        ? {
          key: 'TotalSupply',
          header: '发行总量',
          align: 'left',
          width: 100,
        }
        : null,
      ['NFT', 'FT'].includes(tabActiveId)
        ? {
          key: 'HolderCount',
          header: '持有人数',
          align: 'left',
          width: 100,
        }
        : null,
      {
        key: 'TxNum',
        header: '累计交易数',
        align: 'left',
        width: 120,
      },
      tabActiveId === 'ALL'
        ? {
          key: 'Sender',
          header: '创建用户',
          align: 'left',
          render:({Creator, CreatorAddr, CreatorAddrBns})=><UserAddress UserAddrBns={CreatorAddrBns} Sender={Creator} UserAddr={CreatorAddr}/>
        }
        : null,
      tabActiveId === 'ALL'
        ? {
          key: 'CreateTimestamp',
          header: '创建时间',
          align: 'left',
          width: 180,
          render: ({ CreateTimestamp }) => (CreateTimestamp ? formatUnixTime(CreateTimestamp) : '--'),
        }
        : null,
      {
        key: 'Id',
        header: '操作',
        align: 'center',
        width: 160,
        render: ({ ContractName, ContractAddr, ContractType }) => (
          <div className={liststyle.setting_c}>
            {ContractAddr
              ? (
                <Link to={`/${chainId}/contract/${ContractAddr}?ctype=${ContractTypeMap[ContractType]?.ctype ?? 'other'}`}>
                  <Text theme="primary">查看</Text>
                </Link>
              )
              : (
                <SearchLink type="4" keyWord={ContractName}>
                  <Text theme="primary">查看</Text>
                </SearchLink>
              )}
            {tabActiveId === 'ALL' && <Button type="link" onClick={() => listenContract(ContractName, ContractType)}>
              添加到插件
            </Button>}
          </div>
        ),
      },
    ],
  [tabActiveId],
  );
  return (
    <>
      <PageBox title="合约列表">
        <Justify
          right={
            <>
              <Form className={liststyle.searchform} hideLabel={false} layout="inline">
                {tabActiveId === 'ALL' && <Form.Item label="账户地址">
                  <Input onChange={(sender) => updateparam({ sender })} value={sender} placeholder={authType==='permissionedwithcert'?'请输入创建账户地址/链账户名称':'请输入创建发起账户地址/BNS'} />
                </Form.Item>}
                <Form.Item label="合约名称/地址">
                  <Input
                    onChange={(value) => updateparam({ value })}
                    value={value}
                    placeholder="请输入合约名称/地址搜索"
                  />
                </Form.Item>
                <Form.Item label="">
                  <Button type="primary" onClick={onSearch}>
                  筛选
                  </Button>
                </Form.Item>
              </Form>
            </>
          }
        />
        <Tabs tabs={tabs} placement="top" activeId={tabActiveId} onActive={(tabs) => {
          if (tabActiveId !== tabs.id) {
            setSearchParams({tab: tabs.id}, { replace: true });
            updateparam({ tabActiveId: tabs.id, value: '', sender: '', pageIndex: 1, pageSize: 10 });
            setRecordCount(0);
            setList([]);
          }
        }}>
          <Table
            className={liststyle.table}
            compact={false}
            records={list}
            recordKey="ContractName"
            bordered={true}
            disableTextOverflow={true}
            columns={columns.filter((col) => !!col) as TableColumn[]}
            addons={[
              autotip({
                isLoading,
              }),
              pageable({
                recordCount,
                pageIndex,
                pageSize,
                onPagingChange: (query) => {
                  if (query?.pageIndex) {
                    updateparam({ pageIndex: query.pageIndex });
                  }
                  if (query?.pageSize) {
                    updateparam({ pageSize: query.pageSize });
                  }
                },
              }),
            ]}
          />
        </Tabs>
      </PageBox>
      <PluginDownloadModel visible={pluginDownloadVisible} onClose={() => setPluginDownloadVisible(false)}/>
    </>
  );
}
