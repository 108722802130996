// 根据react-final-form-hooks 的field状态获取相应FormItem扩展属性
interface FormItemField {
  meta: {
    active?: boolean;
    touched?: boolean;
    error?: any;
  };
}
interface FormItemStatus {
  status?: 'error' | 'success' | undefined;
  message?: any;
}
// 根据 form-hooks 的field 获取formItem的status属性
export const getStatus = (field: FormItemField): FormItemStatus => {
  if (field.meta.touched) {
    return { status: field.meta.error ? 'error' : 'success' };
  }
  return {
    status: undefined,
  };
};
// 根据 form-hooks 的field 获取formItem的message属性
export const getMessage = (field: FormItemField): FormItemStatus => {
  const param = getStatus(field);
  if (param.status === 'error') {
    param.message = field.meta.error;
  }
  return param;
};

export default {
  // 链id
  chainId: (value: string) => {
    if (/^[\w\-.]{1,30}$/.test(value)) {
      return undefined;
    }
    return '请输入30位以内字母、数字、中横线、下划线、小数点组合';
  },
  // ip端口号
  Addr: (value: string) => {
    const exp =
      /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5]):([0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-5]{2}[0-3][0-5])$/;
    if (exp.test(value)) {
      return undefined;
    }
    return '请输入正确的节点IP和端口';
  },
  // tls连接使用的域名
  TLSHostName: (value: string) => {
    if (/^[\w.]{1,50}$/.test(value)) {
      return undefined;
    }
    return '请输入50位以内字母、数字、下划线、小数点组合';
  },
  isEmptyString: (value: string) => !/\S/g.test(value),
  orgId: (value: string) => {
    if (/^[\w\-.]{1,30}$/.test(value)) {
      return undefined;
    }
    return '请输入30位以内字母、数字、中横线、下划线、小数点组合';
  },
};
