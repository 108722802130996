import React, { useCallback, useEffect, useMemo, useState } from 'react';
import liststyle from '../../list.module.scss';
import { Table, TableColumn } from 'tea-component';
import { EventItem } from '@src/models';
import { GetEventList } from '@src/utils/apis';
import { useParams } from 'react-router-dom';
import { formatUnixTime } from '@src/utils/tools';
const { pageable, autotip } = Table.addons;

export default function Event({ name }: { name?: string }) {
  const { chainId } = useParams();
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState<EventItem[]>([]);
  const getList = useCallback(() => {
    if (!name) {
      return;
    }
    setIsLoading(true);
    GetEventList({
      Offset: pageIndex - 1,
      Limit: pageSize,
      ChainId: chainId,
      ContractName: name,
    })
      .then((res) => {
        setIsLoading(false);
        if (res.GroupList) {
          setList(res.GroupList);
          setRecordCount(res.TotalCount);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [chainId, pageIndex, pageSize, name]);
  useEffect(() => {
    getList();
  }, [chainId, name, pageIndex, pageSize]);
  const columns = useMemo<TableColumn[]>(
    () => [
      {
        key: 'Timestamp',
        header: '事件时间',
        align: 'left',
        render: ({ Timestamp }) => formatUnixTime(Timestamp),
      },
      {
        key: 'Topic',
        header: '事件主题',
        align: 'left',
      },
      {
        key: 'EventInfo',
        header: '事件内容',
        align: 'left',
      },
    ],
    [],
  );
  return (
    <Table
      className={liststyle.table}
      compact={false}
      records={list}
      bordered={true}
      disableTextOverflow={true}
      columns={columns}
      addons={[
        autotip({
          isLoading,
        }),
        pageable({
          recordCount,
          pageIndex,
          onPagingChange: (query) => {
            if (query?.pageIndex) {
              setPageIndex(query.pageIndex);
            }
            if (query?.pageSize) {
              setPageSize(query.pageSize);
            }
          },
        }),
      ]}
    />
  );
}
